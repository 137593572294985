import React from 'react'

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className = '' }) => {
  return (
    <div
      className={`p-6 border rounded-lg shadow-lg dark:border-gray-600 dark:bg-gray-900 dark:shadow-gray-800 ${className}`}>
      {children}
    </div>
  )
}

export default Card