import React from 'react'

interface SubtitleProps {
  children: React.ReactNode;
  className?: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ children, className = '' }) => {
  return (
    <h3
      className={`text-2xl font-extrabold text-gray-800 dark:text-gray-300 ${className}`}
    >
      {children}
    </h3>
  )
}

export default Subtitle