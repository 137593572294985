import { PaginatedDocs } from 'payload'
import { Project } from '@/payload-types'

export default async function fetchProjects(): Promise<PaginatedDocs<Project>> {
  const response = await fetch(`/api/projects`, {
    method: 'GET',
    credentials: 'include',
    cache: 'no-store',
  })

  if (!response.ok) {
    throw new Error('Failed to fetch projects')
  }

  return response.json()
}
