'use client'

import React from 'react'
import BeveledImage from '@/components/BeveledImage'
import { useQuery } from '@tanstack/react-query'
import fetchMain from '@/client/main'
import RichText from '@/components/RichText'

export function Main() {
  const { data: main } = useQuery({
    queryKey: ['main'],
    queryFn: () => fetchMain(),
  })

  return (
    <main
      id={'main'}
      className={
        'md:mt-15 lg:10 mx-auto mt-10 max-w-7xl scroll-mt-56 px-4 sm:mt-10 sm:px-6 lg:px-8 xl:mt-20'
      }
    >
      <div className={'sm:text-center lg:text-left'}>
        <div className={'flex'}>
          <div className={'lg:w-3/5'}>
            <h1
              className={
                'text-4xl font-extrabold tracking-tight text-primary dark:text-white sm:text-5xl md:text-6xl'
              }
            >
              <span className='block xl:inline'>{main?.slogan}</span>{' '}
            </h1>

            <div
              className={
                'mt-3 text-base text-gray-500 dark:text-gray-400 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0'
              }
            >
              <RichText content={main?.description} />
            </div>

            <div
              className={
                'mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'
              }
            >
              <div className='rounded-md shadow-sm'>
                <a
                  href={`tel:${main?.phoneNumber}`}
                  className={`flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-background hover:bg-border hover:text-primary dark:bg-border dark:text-primary dark:hover:bg-primary dark:hover:text-background md:px-10 md:py-4 md:text-lg`}
                  target='_blank'
                >
                  {main?.phoneNumber}
                </a>
              </div>
              <div className='mt-3 sm:ml-3 sm:mt-0'>
                <a
                  href={`mailto:${main?.mail}`}
                  className={`flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-background hover:bg-border hover:text-primary dark:bg-border dark:text-primary dark:hover:bg-primary dark:hover:text-background md:px-10 md:py-4 md:text-lg`}
                  target='_blank'
                >
                  {main?.mail}
                </a>
              </div>
            </div>
          </div>

          <BeveledImage
            src='/rene_small.webp'
            alt='Bild von Rene Kathofer'
            className='hidden lg:block lg:w-2/5'
          />
        </div>
        <div>
          <BeveledImage
            src='/rene_small.webp'
            alt='Bild von Rene Kathofer'
            className='mx-auto mt-5 block w-1/2 lg:hidden'
          />
        </div>
      </div>
    </main>
  )
}
