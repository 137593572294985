import React from 'react'

interface SubtextProps {
  children: React.ReactNode;
  className?: string;
}

const Subtext: React.FC<SubtextProps> = ({ children, className = '' }) => {
  return (
    <p className={`italic text-gray-500 dark:text-gray-500 ${className}`}>
      {children}
    </p>
  )
}

export default Subtext