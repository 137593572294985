import React, { JSX } from 'react'

import { DefaultNodeTypes, SerializedBlockNode } from '@payloadcms/richtext-lexical'

import {
  IS_BOLD,
  IS_CODE,
  IS_ITALIC,
  IS_STRIKETHROUGH,
  IS_SUBSCRIPT,
  IS_SUPERSCRIPT,
  IS_UNDERLINE,
} from './nodeFormat'
import type { MediaBlock as MediaBlockProps } from '@/payload-types'
import { MediaBlock } from '@/blocks/MediaBlock/Component'

export type NodeTypes =
  | DefaultNodeTypes
  | SerializedBlockNode<MediaBlockProps>

type Props = {
  nodes: NodeTypes[]
}

export function serializeLexical({ nodes }: Props): JSX.Element {
  return (
    <>
      {nodes?.map((node, index): JSX.Element | null => {
          if (node == null) {
            return null
          }

          if (node.type === 'text') {
            const formatMap: { [key: number]: (content: React.ReactNode) => React.ReactNode } = {
              [IS_BOLD]: (content) => <strong key={index}>{content}</strong>,
              [IS_ITALIC]: (content) => <em key={index}>{content}</em>,
              [IS_STRIKETHROUGH]: (content) => (
                <span key={index} style={{ textDecoration: 'line-through' }}>
                  {content}
                </span>
              ),
              [IS_UNDERLINE]: (content) => (
                <span key={index} style={{ textDecoration: 'underline' }}>
                  {content}
                </span>
              ),
              [IS_CODE]: () => <code key={index}>{node.text}</code>,
              [IS_SUBSCRIPT]: (content) => <sub key={index}>{content}</sub>,
              [IS_SUPERSCRIPT]: (content) => <sup key={index}>{content}</sup>,
            }

            let text: React.ReactNode = <React.Fragment key={index}>{node.text}</React.Fragment>

            Object.keys(formatMap).forEach((key) => {
              const formatKey = Number(key)
              if (node.format & formatKey) {
                text = formatMap[formatKey](text)
              }
            })

            return text
          }

          const serializeChildren = (node: NodeTypes): JSX.Element | null => {
            if (!node?.children) {
              return null
            }

            if (node.type === 'list' && node.listType === 'check') {
              node.children.forEach((item) => {
                if ('checked' in item && item.checked === undefined) {
                  item.checked = false
                }
              })
            }

            return serializeLexical({ nodes: node.children as NodeTypes[] })
          }

          const serializedChildren = node?.children ? serializeChildren(node) : ''

          if (node.type === 'block') {
            const { fields: block } = node
            const blockType = block?.blockType

            if (!block || !blockType) return null

            switch (blockType) {
              case 'mediaBlock':
                return <MediaBlock key={index} {...block} />
              default:
                return null
            }
          }

          switch (node.type) {
            case 'linebreak':
              return <br className="col-start-2" key={index} />

            case 'paragraph':
              return (
                <p className="col-start-2" key={index}>
                  {serializedChildren}
                </p>
              )

            case 'heading': {
              const Tag = node.tag || 'h1' // Default to 'h1' if tag is not specified
              return (
                <Tag className="col-start-2" key={index}>
                  {serializedChildren}
                </Tag>
              )
            }

            case 'list': {
              const ListTag = node.tag || 'ul' // Default to 'ul' for list if not specified
              return (
                <ListTag className="col-start-2 list" key={index}>
                  {serializedChildren}
                </ListTag>
              )
            }

            case 'listitem': {
              const isChecked = node?.checked != null
              return (
                <li
                  key={index}
                  value={node?.value}
                  {...(isChecked && {
                    ariaChecked: node.checked ? 'true' : 'false',
                    role: 'checkbox',
                    tabIndex: -1,
                  })}
                >
                  {serializedChildren}
                </li>
              )
            }

            case 'quote':
              return (
                <blockquote className="col-start-2" key={index}>
                  {serializedChildren}
                </blockquote>
              )

            case 'link':
              return (
                <></>
              )

            default:
              return null
          }
        },
      )
      }
    </>
  )
}
