import React from 'react'

type ContainerProps = {
  id: string
  children: React.ReactNode
}

export function Container({ id, children }: ContainerProps) {
  return (
    <>
      <div id={id} style={{ marginTop: -35, position: 'absolute' }}></div>
      <section
        className={`md:mt-15 mx-auto mt-10 max-w-7xl p-6 sm:p-0 sm:px-6 xl:mt-20`}
      >
        <div className={`container mx-auto mt-8 max-w-6xl sm:p-6`}>
          {children}
        </div>
      </section>
    </>
  )
}
