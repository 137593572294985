import React from 'react'

type TitleProps = {
  children: React.ReactNode
}

export function Title({ children }: TitleProps) {
  return (
    <h2
      className={`my-2 w-full text-center text-4xl font-bold leading-tight text-primary dark:text-white`}
    >
      {children}
    </h2>
  )
}
