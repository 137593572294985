'use client'

import React from 'react'
import { Media } from '@/payload-types'
import { Title } from '@/components/Typography/Title'
import { Container } from '@/components/Layout/Container'
import Image from 'next/image'
import Subtitle from '@/components/Typography/Subtitle'
import Text from '@/components/Typography/Text'
import Subtext from '@/components/Typography/Subtext'
import Content from '@/components/Layout/Content'
import { useQuery } from '@tanstack/react-query'
import fetchServices from '@/client/services'

function isMedia(portrait: number | Media): portrait is Media {
  return (portrait as Media)?.id !== undefined
}

export function Services() {
  const { data: services } = useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(),
  })

  return (
    <Container id={'services'}>
      <Title>Meine Services</Title>
      <Content>
        {services &&
          services.docs.map((service, index) => (
            <div
              key={service.id}
              className={`flex flex-col ${index % 2 === 0 ? 'sm:flex-row' : 'sm:flex-row-reverse'} w-full items-center`}
            >
              <div className='flex w-full flex-col space-y-3 sm:w-1/2'>
                <Subtitle>{service?.title}</Subtitle>
                <Subtext>{service?.subtitle}</Subtext>
                <Text>{service?.description}</Text>
              </div>
              {service.image && isMedia(service.image) && service.image.url && (
                <div className='w-full justify-items-center p-6 sm:w-1/2'>
                  <Image
                    className='h-3/4 w-3/4 object-contain'
                    src={service.image.url}
                    alt={service?.title}
                    width={0}
                    height={0}
                    sizes='100vw'
                  />
                </div>
              )}
            </div>
          ))}
      </Content>
    </Container>
  )
}
