import { PaginatedDocs } from 'payload'
import { Service } from '@/payload-types'

export default async function fetchServices(): Promise<PaginatedDocs<Service>> {
  const response = await fetch(
    `/api/service?sort=sort&where[_status][equals]=published`,
    {
      method: 'GET',
      credentials: 'include',
      cache: 'no-store',
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }

  return response.json()
}
