import React from 'react'

interface TextProps {
  children: React.ReactNode;
  className?: string;
}

const Text: React.FC<TextProps> = ({ children, className = '' }) => {
  return (
    <p className={`mt-2 text-gray-600 dark:text-gray-400 ${className}`}>
      {children}
    </p>
  )
}

export default Text