'use client'

import React from 'react'
import { Container } from '@/components/Layout/Container'
import { Title } from '@/components/Typography/Title'
import Card from '@/components/Layout/Card'
import Subtitle from '@/components/Typography/Subtitle'
import Text from '@/components/Typography/Text'
import Subtext from '@/components/Typography/Subtext'
import Content from '@/components/Layout/Content'
import { useQuery } from '@tanstack/react-query'
import fetchProjects from '@/client/projects'

export function Projects() {
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => fetchProjects(),
  })

  return (
    <Container id={'projects'}>
      <Title>Projekte</Title>
      <Content
        className={'grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'}
      >
        {projects &&
          projects.docs.map((project) => (
            <Card key={project.id} className={'flex h-full flex-col'}>
              <div className={'grow'}>
                <Subtitle>{project.title}</Subtitle>
                <Text>{project.description}</Text>
              </div>
              <div className={'mt-4 self-end'}>
                <Subtext>{new Date(project.endDate).getFullYear()}</Subtext>
              </div>
            </Card>
          ))}
      </Content>
    </Container>
  )
}
